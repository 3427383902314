.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.90);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;


  // Mobile Menu Logo
  .mobile-logo {
    padding: 0 0 0 15px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    // background-color: $smoke-color2;
    border-bottom: 1px solid #757575;

    img {
      max-width: 187px;
      width: 187px;
    }
  }

  // Menu Close Button
  .menu-toggle {
    border: 0;
    border-left: 1px solid #757575;
    font-size: 22px;
    right: -16.5px;
    top: 25px;
    padding: 20px 30px 20px 30px;
    line-height: 1;
    line-height: 36px;
    font-size: 18px;
    z-index: 1;
    color: $white-color;
    background-color: transparent;
    border-radius: 0;

    &:hover {
      background-color: $theme-color;
      color: $white-color;
    }
  }

  .mobile-menu-area {
    width: 100%;
    max-width: 310px;
    background-color: #212121;
    height: 100%;
    position: relative;
    left: -110%;
    opacity: 0;
    visibility: hidden;
    transition: all ease 1s;
    z-index: 1;
  }

  &.body-visible {
    opacity: 1;
    visibility: visible;

    .mobile-menu-area {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  }
}


// Mobile Menu
.mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 20px;
  text-align: left;

  ul {
    margin: 0;
    padding: 0 0;

    li {
      border-bottom: 1px solid #757575;
      list-style-type: none;

      li:first-child {
        border-top: 1px solid #fdedf1;
      }

      a {
        display: block;
        position: relative;
        padding: 12px 0;
        line-height: 1.4;
        font-size: 16px;
        text-transform: capitalize;
        color: $white-color;
        padding-left: 0px;
      }

      &.active-class {
        >a {
          color: $theme-color;

          &:before {
            transform: rotate(90deg);
          }
        }
      }

      ul {
        li {
          padding-left: 20px;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .submenu-item-has-children {
      >a {
        .mean-expand-class {
          position: absolute;
          right: 0;
          top: 50%;
          font-weight: 400;
          font-size: 14px;
          width: 100%;
          height: 100%;
          line-height: 25px;
          margin-top: -12.5px;
          display: inline-block;
          text-align: end;
          background-color: transparent;
          color: $title-color;
          box-shadow: none;
          border-radius: 0;

          &:before {
            content: '\f105';
            font-family: $icon-font;
            font-weight: 700;
          }
        }
      }

      &.active-class {
        >a {
          .mean-expand-class:before {
            content: '\f107';
          }
        }
      }
    }
  }

  >ul {
    padding: 0 30px;

    >li {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}


@media (max-width: 400px) {
  .mobile-menu-wrapper .mobile-menu-area {
    width: 100%;
    max-width: 300px;
  }

  .mobile-menu>ul {
    padding: 0 20px;
  }
}